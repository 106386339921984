<template>
  <div class="box" v-class="boxAni">
    <p>@2018-{{ nowTime }} 合肥和行科技有限公司 All Rights Reserved {{ nowTime }} 和行约车.</p>
    <!-- <p>皖公网安备 34019102000434号  皖ICP备18015830号-1</p> -->
    <div class="bot">
      <img src="../../../static/images/home/bottomlogo.png">
      <p> 皖公网安备 34019102000434号 皖ICP备18015830号-1.</p>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    animate: {
      type: Boolean,
      default: true
    },
    time: ""
  },
  data() {
    return {
      nowTime: new Date().getFullYear()
    }
  },
  watch: {
    time(newval, oldval) {
      this.nowTime = newval
    }
  },
  computed: {
    boxAni() {
      const { animate } = this;
      return animate ? 'box animate__animated animate__fadeInup animate__block' : 'box animate__block'
    }
  },

}
</script>

<style scoped>
.box {
  padding: .46rem;
  background: #F5F5F5;
  visibility: hidden;
}

p {
  color: #848494;
  font-size: .18rem;
}

/* p:nth-child(2) {
    margin-top: .2rem;
} */
.bot {
  display: flex;
  align-items: center;
  margin-top: .2rem;
}

.bot img {
  width: 12px;
  height: 12px;
  margin-right: 5px;
}
</style>