<template>
    <div class="layout">
        <!-- 手机端 -->
        <div class="mobile" v-if="isMobile()">
            <side-bar></side-bar>
            <router-view style="margin-top:0.9rem"></router-view>
        </div>
        <!-- PC端 -->
        <div v-else>
            <div class="left">
                <hea-der></hea-der>
            </div>
            <div class="right">
                <router-view></router-view>
            </div>
        </div>
    </div>
</template>
<script>
import SideBar from '../mobile/components/sidebar.vue'
import HeaDer from './header.vue';
import {getLocalStorage,removeLocalStorage} from '@/utils/js/tools.js'
export default {
    components:{HeaDer, SideBar},
    methods:{
        // 确定是否是手机端
        isMobile() {
            let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
            return flag;
        }
    },
    mounted() {
        // if (this.isMobile()) {
        //     if(getLocalStorage('warpath') && getLocalStorage('warpath') != ''){
        //         this.$router.replace(getLocalStorage('warpath'));
        //     }else{
        //         this.$router.replace('/mobile/index');
        //     }
        //     removeLocalStorage('path')
        // } else {
        //     if(getLocalStorage('path') && getLocalStorage('path') != ''){
        //         this.$router.replace(getLocalStorage('path'));
        //     }else{
        //         this.$router.replace('/')
        //     }
        //     removeLocalStorage('warpath')
        // }
    },
}
</script>

<style lang="less" scoped>
.layout{
    width:100%;
    .left{
        width:16%;
        float: left;
        height:100vh;
    }
    .right{
        width:84%;
        float: right;
    }
}
</style>