import axios from 'axios';
var baseURL = 'https://activity.hexingyueche.com/activity/api/drvRecruit/add'
function https(method, param){
    param = param && typeof param === 'object' ? param : {};
    const config = {
        url: baseURL,
        method: method,
        responseType: 'json',
        headers: {
            'X-Requested-With': 'XMLHttpRequest'
        }
    };

    // post请求时需要设定Content-Type
    if (method === 'post') {
        config.headers['Content-Type'] = 'application/json; charset=UTF-8';
        config.data = param;
    } else if (method === 'get') {
        config.params = param;
    }
    return axios(config);
}
export {
    https
};