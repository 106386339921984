export const mixins = {
    data(){
        return {

        }
    },
    methods:{
        routerTo(type){
            var ifmo = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
            var url = '';
            switch(type){
                case 1:
                  url = ifmo ? '/mobile/index' : '/';
                  break;
                case 2:
                  url = ifmo ? '/mobile/driverRecruit' : '/driverRecruitment'
                  break;
                case 3:
                  url = ifmo ? '/mobile/service/express' : '/travelService/express'
                  break;
                case 4:
                  url = ifmo ? '/mobile/service/hitchRide' : '/travelService/hitchRide'
                  break;
                case 5:
                  url = ifmo ? '/mobile/service/vioceTaxi' : '/travelService/voiceTaxi'
                  break;
                case 6:
                  url = ifmo ? '/mobile/service/businessCar' : '/travelService/businessCar'
                  break;
            }
            this.$router.replace(url);
        }
    }
}