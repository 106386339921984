<template>
  <div class="sidebar_box">
    <!-- 侧边栏按钮动画 -->
    <div class="title" @touchmove.prevent>
      <div class="title_btn" @click="handleClick">
        <div class="line first" :style="firstStyle"></div>
        <div class="line second" :style="secondStyle"></div>
      </div>
      <img class="title_logo" src="../../../static/images/home/logo-su.png" />
    </div>
    <div class="sidebar" :style="sidebarStyle" @touchmove.prevent>
      <div class="main_title" v-for="(items, index) in menu" :key="index">
        <div class="main" @click="mainClick(index)">{{ items.mainTitle }}</div>
        <div class="sub" v-if="isFold">
          <div v-if="samlllist">
            <div
              class="sub_title"
              v-for="(item, i) in items.subTitle1"
              :key="i"
              @click="subClick1(i)"
            >
              {{ item.title }}
            </div>
          </div>
          <div v-else>
            <div
              class="sub_title"
              v-for="(item, i) in items.subTitle"
              :key="i"
              @click="subClick(i)"
            >
              {{ item.title }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      firstStyle: {
        transform: "translateY(0) rotate(0deg)",
      },
      secondStyle: {
        transform: "translateY(0) rotate(0deg)",
      },
      sidebarStyle: {
        transform: "translateX(-100%)",
      },
      activeIndex: 0,
      isFold: false,
      samlllist: false,
      menu: [
        {
          mainTitle: "首页",
        },
        {
          mainTitle: "出行服务",
          subTitle1: [
            {
              title: "快车",
            },
            {
              title: "顺风车",
            },
            {
              title: "语音的士",
            },
            {
              title: "企业用车",
            },
          ],
        },
        // {
        //   mainTitle: "和行业务",
        // },
        {
          mainTitle: "司机招募",
        },
        {
          mainTitle: "关于我们",
          subTitle: [
            {
              title: "董事长寄语",
            },
            {
              title: "企业责任",
            },
            {
              title: "企业党建",
            },
          ],
        },
      ],
    };
  },
  methods: {
    handleClick() {
      if (this.sidebarStyle.transform == "translateX(-100%)") {
        this.firstStyle.transform = "translateY(.11rem) rotate(45deg)";
        this.secondStyle.transform = "translateY(-.11rem) rotate(-45deg)";
        this.sidebarStyle.transform = "translateX(0%)";
      } else {
        this.cssTrans();
      }
    },
    mainClick(index) {
      switch (index) {
        case 0:
          if (this.$route.path != "/mobile/index") {
            this.$router.push({ path: "/mobile/index" });
          }
          this.cssTrans();
          break;
        case 1:
          this.isFold = !this.isFold;
          this.samlllist = true;
          break;
        // case 2:
        //   window.open("https://www.jachx.com");
        //   this.cssTrans();
        //   break;
        case 2:
          if (this.$route.path != "/mobile/driverRecruit") {
            this.$router.push({ path: "/mobile/driverRecruit" });
          }
          this.cssTrans();
          break;
        case 3:
          this.isFold = !this.isFold;
          this.samlllist = false;
          break;
      }
      this.activeIndex = index;
    },
    subClick1(i) {
      switch (i) {
        case 0:
          if (this.$route.path != "/mobile/service/express") {
            this.$router.push({ path: "/mobile/service/express" });
          }
          this.cssTrans();
          break;
        case 1:
          if (this.$route.path != "/mobile/service/hitchRide") {
            this.$router.push({ path: "/mobile/service/hitchRide" });
          }
          this.cssTrans();
          break;
        case 2:
          if (this.$route.path != "/mobile/service/vioceTaxi") {
            this.$router.push({ path: "/mobile/service/vioceTaxi" });
          }
          this.cssTrans();
          break;
        case 3:
          if (this.$route.path != "/mobile/service/businessCar") {
            this.$router.push({ path: "/mobile/service/businessCar" });
          }
          this.cssTrans();
          break;
      }
    },
    subClick(i) {
      switch (i) {
        case 0:
          if (this.$route.path != "/mobile/ourself/message") {
            this.$router.push({ path: "/mobile/ourself/message1" });
          }
          this.cssTrans();
          break;
        case 1:
          if (this.$route.path != "/mobile/ourself/duty1") {
            this.$router.push({ path: "/mobile/ourself/duty1" });
          }
          this.cssTrans();
          break;
        case 2:
          if (this.$route.path != "/mobile/partyBuilding/index") {
            this.$router.push({ path: "/mobile/partyBuilding/index" });
          }
          this.cssTrans();
          break;
      }
    },
    cssTrans() {
      this.firstStyle.transform = "translateY(0) rotate(0deg)";
      this.secondStyle.transform = "translateY(0) rotate(0deg)";
      this.sidebarStyle.transform = "translateX(-100%)";
    },
  },
};
</script>

<style scoped>
.sidebar_box {
  width: 100vw;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
}
.title {
  display: flex;
  align-items: center;
  height: 0.9rem;
  padding: 0 0.48rem;
  box-sizing: border-box;
  background-color: #ffffff;
  width: 100%;
  position: fixed;
  z-index: 99999;
}

.title .title_btn .line {
  width: 0.48rem;
  height: 0.02rem;
  background: #2d2d39;
  transition-duration: 0.3s;
}

.title .title_btn .second {
  margin-top: 0.2rem;
}

.title_logo {
  width: 1.69rem;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.sidebar {
  z-index: 999;
  width: 100%;
  height: calc(100vh - 0.9rem);
  top: 0.9rem;
  /* 将该框架的内容左移100% 隐藏看不见 */
  transform: translateX(-100%);
  transition-duration: 0.3s;
  padding: 0.3rem 0 0 0;
  box-sizing: border-box;
  background-color: #ffffff;
  position: fixed;
}

.sidebar .main_title .main {
  color: #2d2d39;
  font-size: 0.36rem;
  padding: 0.35rem 0 0.35rem 1.12rem;
}

.sidebar .main_title .main:hover,
.sidebar .main_title .sub .sub_title:hover {
  background: rgba(238, 238, 238, 0.5);
}

.sidebar .main_title .sub .sub_title {
  color: #757586;
  font-size: 0.28rem;
  padding: 0.35rem 0 0.35rem 1.12rem;
}
</style>