<template>
    <div class="box" v-class="'box animate__animated animate__fadeInup animate__block'">
        <img class="banner" :src="imgSrc" alt="">
        <span v-if="!logoFlag">{{maintitle}}</span>
        <img class="logo" v-else src="../../../static/images/home/logo.png" alt="">
        <p>{{subtitle}}</p>
    </div>
</template>

<script>
export default {
    props: {
        logoFlag: Boolean,
        maintitle: String,
        subtitle: String,
        imgSrc: String
    }
}
</script>

<style scoped>
.box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 5rem;
    position: relative;
    visibility: hidden;
}
.box .banner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 5rem;
    z-index: -1;
}
.box span {
    color: #FFFFFF;
    font-size: .36rem;
}
.box .logo {
    width: .59rem;
}
.box p {
    font-size: .24rem;
    color: #FFFFFF;
    font-family: "Microsoft YaHei";
    letter-spacing: .02rem;
    margin: .22rem 0 0;
    text-align: center;
}
</style>