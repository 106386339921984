<template>
    <div class="title" v-class="'title animate__animated animate__fadeInup animate__block'">
        <span>{{title}}</span>
        <p>{{subtitle}}</p>
    </div>
</template>

<script>
export default {
    props: {
        title: String,
        subtitle: String
    }
}
</script>

<style scoped>
.title {
    color: #2D2D39;
    font-size: .36rem;
    line-height: .36rem;
    margin: 0 .48rem;
    visibility: hidden;
}
.title p {
    font-size: .16rem;
    line-height: .16rem;
    margin-top: .09rem;
}
</style>